import { CLabel } from "@coreui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { formatDouble, formatFilter } from "./tableUtils";

export const useInput = (ele) => {
  const [value, setValue] = useState(ele);
  return {
    value,
    reset: () => setValue(""),
    setValue: (val) => setValue(val),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
    },
  };
};

export const useInputWithFunc = (ele, callBackfunct) => {
  const [value, setValue] = useState(ele);
  return {
    value,
    reset: () => setValue(""),
    setValue: (val) => {
      setValue(val);
      callBackfunct(val);
    },
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
        callBackfunct(event.target.value);
      },
    },
  };
};

export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function CheckPassword(inputtxt) {
  var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  if (inputtxt.match(passw)) {
    return true;
  } else {
    return false;
  }
}

export function stringLength(input, length) {
  if (input.length >= length) {
    return true;
  } else {
    return false;
  }
}

export const handleLoading = async () => {
  await Swal.fire({
    title: "Wait ...",
    onBeforeOpen() {
      Swal.showLoading();
    },
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};
export const handleSucces = async (successText, title) => {
  await Swal.fire({
    toast: true,
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
    icon: "success",
    width: 300,
    height: 200,
    title: title,
    text: successText,
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  }).then(() => {
    return Promise.resolve("success");
  });
};

export const handleInfoToast = async (text, title = "") => {
  await Swal.fire({
    toast: true,
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
    icon: "info",
    width: 300,
    height: 200,
    title: title,
    text: text,
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  }).then(() => {
    return Promise.resolve("success");
  });
};

export const handleInfo = (messageText, title = "Empty", icon = "info") => {
  Swal.fire({
    icon: icon,
    title: title,
    text: messageText,
  });
};

export const handleDelete = async (messageText, action) => {
  await Swal.fire({
    icon: "warning",
    title: "Warning",
    text: messageText,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    denyButtonText: `Cancel`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      handleSucces("success");
      await action();
      return Promise.resolve("success");
    }
    return Promise.reject("cancel");
  });
};

export const handleInput = async (
  messageText,
  icon = "warning",
  title = "Need some more data",
  type = "text"
) => {
  return await Swal.fire({
    icon: icon,
    title: title,
    text: messageText,
    input: type,
    showLoaderOnConfirm: true,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    denyButtonText: `Cancel`,
  }).then((result) => {
    if (result.isConfirmed) {
      return Promise.resolve(result?.value);
    }
    return Promise.reject("cancel");
  });
};

export const safeQueryBuilder = (query, params) => {
  if (!!!params) return query;
  for (var key in params) {
    if (
      !query.includes("?") &&
      (typeof params[key] == "number" || params[key])
    ) {
      query = query + "?" + key + "=" + params[key];
    } else if (typeof params[key] == "number" || params[key]) {
      query = query + "&" + key + "=" + params[key];
    }
  }
  return query;
};

export const searchQueryBuilder = (query, params) => {
  if (!!!params) return query;
  params.map((data, index) => {
    if (
      !query.includes("search") &&
      (typeof data.value == "number" || data.value) &&
      data.operation
    ) {
      var search = "&search=";
      if (!query.includes("?")) {
        search = "?search=";
      }
      query =
        query + search + data.key + data.operation + formatFilter(data.value);
    } else if (
      (typeof data.value == "number" || data.value) &&
      data.operation
    ) {
      query =
        query + "," + data.key + data.operation + formatFilter(data.value);
    }
  });

  return query;
};

export const downloadAsCsv = (content, fileName, mimeType) => {
  var a = document.createElement("a");
  mimeType = mimeType || "application/octet-stream";

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    );
  } else if (URL && "download" in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href =
      "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
  }
};

export const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
    }}
  />
);

export const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      // console.dir(error);
      return getErrorsFromValidationError(error);
    }
  };
};

export const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner?.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const cleanFormData = (form) => {
  Object.keys(form ?? {}).forEach(
    (key) =>
      (form[key] === undefined ||
        form[key] === null ||
        form[key] === "" ||
        form[key] === "Invalid date") &&
      delete form[key]
  );
  return form;
};

/** below is utils used in transaction creation and update*/

export const updateTransactionTotalValue = (
  dataM,
  { setFieldValue, values, delivery }
) => {
  var delivery = Number(delivery || (values?.delivery ?? 0));
  if (dataM == undefined || (dataM && dataM.length < 1)) {
    setFieldValue("value", 0);
    return;
  }

  var total = dataM
    ?.map((row) => row.subTotal)
    ?.reduce((subTotal, curr) => Number(curr) + Number(subTotal));

  setFieldValue("value", twoDecimal(Number(total) + delivery));
};

export const handleDeleteRow = async (
  index,
  { setFieldValue, values, invFeId }
) => {
  let newData = [...values?.dataM];
  // console.log(invFeId);
  if (invFeId) {
    index = newData?.findIndex((e) => e.invFeId == invFeId);
  }

  let dataToRemove = newData[index];
  // console.log(`index= ${index}`);
  // console.log(newData);
  if (index > -1) {
    // only splice array when item is found
    newData.splice(index, 1); // 2nd parameter means remove one item only
  }
  // console.log(newData);
  if (!!!dataToRemove?.invGroupId) {
    newData = newData.filter((item) => {
      // console.log(
      //   `${item?.invGroupId} | ${dataToRemove.invFeId} = ${
      //     item?.invGroupId != dataToRemove.invFeId
      //   }`
      // );
      return !!!item?.invGroupId || item?.invGroupId != dataToRemove.invFeId;
    });
  }

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

export const newLine = {
  invFeId: uuid(),
  sku: "-",
  name: "-",
  discountType: "flat",
  discount: 0,
  itemType: "DYNAMIC",
  description: "-",
  priceBuyPerUnit: 0,
  priceSellPerUnit: 0,
  stockToCount: 0,
  subTotal: 0,
};

export const addNewLine = ({ setFieldValue, values, index }) => {
  setFieldValue("dataM", [...values?.dataM, newLine]);
  return true;
};

export const handleTrxTypeChange = ({ values, setFieldValue }) => {
  let newData = values?.dataM?.map((product) => {
    const priceUnit = values?.trxType
      ? product?.priceBuyPerUnit
      : product?.priceSellPerUnit;
    product.subTotal = priceUnit * product.stockToCount;
    return product;
  });

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

export const sorter = (e, { setSortBy, setIsAsc }) => {
  if (e.column === "billStatus") {
    setSortBy("status");
  } else if (e.column === "isActive") {
    setSortBy("active");
  } else if (e.column === "partner") {
    setSortBy("partnerName");
  } else if (e.column === "spend") {
    setSortBy("sale");
  } else if (e.column) {
    setSortBy(e.column);
  }
  if (e.asc != undefined) setIsAsc(e.asc);
};

export const sorterConfig = { external: true, resetable: false };

/** below use for product extraction */

export const extractProductOptions = (responseToParse, mapFn) =>
  responseToParse?.map((eachData) => {
    return mapFn ? mapFn(eachData) : mapProduct(eachData);
  });

export const mapProductSimple = (eachData) => {
  return {
    value: eachData?.invProductId,
    label: <label className="pb-0 mb-0">{eachData?.name}</label>,
    sku: eachData?.sku,
    name: eachData?.name,
    moq: eachData?.moq,
    stockToCount: eachData?.moq,
    discountType: "flat",
    discount: 0,
    description: eachData?.description,
    itemType: eachData?.itemType ?? "DYNAMIC",
    priceBuyPerUnit: eachData?.priceBuyPerUnit,
    stockToCount: eachData?.stockToCount,
    priceSellPerUnit: eachData?.priceSellPerUnit,
    partnerId: eachData?.partnerId,
    printerId: eachData?.printerId,
  };
};

export const mapProduct = (eachData) => {
  return {
    value: eachData?.invProductId,
    label: (
      <label className="pb-0 mb-0">
        {eachData?.name}
        {eachData?.sku?.trim() ? <br /> : ""}
        <small>{eachData?.sku?.trim() ? eachData?.sku?.trim() : ""}</small>
        {eachData?.sku?.trim() ? <br /> : ""}
        <small className="text-muted pb-0 mb-0">
          {eachData?.inventoryName?.trim()}
        </small>
        {eachData?.inventoryName?.trim() ? <br /> : ""}
        <small className="text-muted pb-0 mb-0">
          ready stock: {eachData?.readyStock}
        </small>
      </label>
    ),
    sku: eachData?.sku,
    name: eachData?.name,
    moq: eachData?.moq,
    stockToCount: eachData?.moq,
    discountType: "flat",
    discount: 0,
    description: eachData?.description,
    itemType: eachData?.itemType ?? "DYNAMIC",
    priceBuyPerUnit: eachData?.priceBuyPerUnit,
    stockToCount: eachData?.stockToCount,
    priceSellPerUnit: eachData?.priceSellPerUnit,
    partnerId: eachData?.partnerId,
    printerId: eachData?.printerId,
  };
};

export const extractBarcodeOptions = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapBarcodeProduct(eachData);
  });

export const mapBarcodeProduct = (eachData) => {
  return {
    value: eachData?.invProductSkuId,
    label:
      eachData?.barcodeId +
      (eachData?.name
        ? " | " +
          eachData?.name +
          " (" +
          eachData?.status?.replace("_", " ") +
          ")"
        : ""),
    sku: eachData?.sku,
    name: eachData?.name,
    moq: eachData?.moq,
    invProductId: eachData?.invProductId,
    stockToCount: eachData?.moq,
    discountType: "flat",
    discount: 0,
    description: eachData?.product?.description,
    itemType: eachData?.itemType ?? "DYNAMIC",
    priceBuyPerUnit: eachData?.priceBuyPerUnit,
    stockToCount: eachData?.stockToCount,
    priceSellPerUnit: eachData?.priceSellPerUnit,
    partnerId: eachData?.partnerId,
    printerId: eachData?.printerId,
    barcodeId: eachData?.barcodeId,
    barcodes: JSON.parse(JSON.stringify(eachData)),
  };
};

export const handleStockChange = (
  index,
  key,
  data,
  { setFieldValue, values, invFeId, label }
) => {
  let newData = [...values?.dataM];

  if (invFeId) {
    index = newData?.findIndex((e) => e.invFeId == invFeId);
  }

  if (["stockToCountProduct"].includes(key)) {
    newData[index][key] = Number(data ?? 0);
  } else {
    newData[index][key] = data;
  }

  if (
    [
      "priceSellPerUnit",
      "priceBuyPerUnit",
      "stockToCount",
      "discount",
      "discountType",
    ].includes(key)
  ) {
    const priceUnit =
      (values?.trxType && !["DEBITSpAcENOTE"].includes(values?.configType)) ||
      ["CREDITSpAcENOTE"].includes(values?.configType)
        ? newData[index]["priceSellPerUnit"]
        : newData[index]["priceBuyPerUnit"];
    var subTotal = twoDecimal(priceUnit * newData[index]["stockToCount"]);

    if (
      newData[index]["discountType"] == "percent" &&
      newData[index]["discount"]
    ) {
      subTotal = (subTotal * (100 - newData[index]["discount"] ?? 0)) / 100;
    } else if (
      newData[index]["discountType"] == "flat" &&
      newData[index]["discount"]
    ) {
      subTotal = subTotal - newData[index]["discount"] ?? 0;
    }

    newData[index]["subTotal"] = subTotal;
  }

  if (["salePersonId", "inChargePersonId"].includes(key)) {
    newData[index][
      key === "salePersonId" ? "salePersonName" : "inChargePersonName"
    ] = label;
  }

  setFieldValue("dataM", newData);
  updateTransactionTotalValue(newData, { setFieldValue, values });
};

/**Partner helper */

export const extractPartnerOptions = (responseToParse, configType) =>
  responseToParse?.map((eachData) => {
    if (eachData?.type == "SUPPLIER") {
      return {
        key: eachData?.partnerId,
        value: eachData?.partnerId,
        label: (
          <CLabel className="pb-0 mb-0">
            {`${eachData?.shortcode} | ΣDN ${formatDouble(
              eachData?.debitNoteBalance ?? 0
            )} | ΣPI ${formatDouble(eachData?.spend ?? 0)} | ΣPP ${formatDouble(
              (eachData?.cash ?? 0) - (eachData?.spend ?? 0)
            )}`}
          </CLabel>
        ),
      };
    }

    return {
      value: eachData?.partnerId,
      label: (
        <CLabel className="pb-0 mb-0">
          <p className="pb-0 mb-0">
            {eachData?.name + (eachData?.telNo ? " | " + eachData?.telNo : "")}
            <br />
            <small className="text-muted">
              total credit note: RM
              {formatDouble(eachData?.creditNoteBalance ?? 0)}
            </small>
            <br />
            <small className="text-muted">
              total sales: RM{formatDouble(eachData?.spend ?? 0)}
            </small>
            {(eachData?.cash ?? 0) > (eachData?.spend ?? 0) ? (
              <>
                <br />
                <small className="text-muted">
                  extra payment: RM
                  {formatDouble((eachData?.cash ?? 0) - (eachData?.spend ?? 0))}
                </small>
              </>
            ) : (
              <>
                <br />
                <small className="text-muted">
                  total pending payment: RM
                  {formatDouble((eachData?.cash ?? 0) - (eachData?.spend ?? 0))}
                </small>
              </>
            )}
          </p>
        </CLabel>
      ),
    };
  });

export const extractStaffOptions = (responseToParse, configType) =>
  responseToParse?.map((eachData) => {
    return {
      value: eachData?.partnerId,
      label: eachData?.shortcode ?? eachData?.name,
    };
  });

export const extractGroupOptions = (data) =>
  data
    ? data.map((group) => {
        return {
          value: group.partnerGroupId,
          label: group.name,
          partnerGroupId: group.partnerGroupId,
        };
      })
    : data;

export const setInitialProductList = (products, trxType) => {
  if (!products) return [];
  return products.map((product) => {
    var subTotal = trxType
      ? twoDecimal(product?.stockToCount * product?.priceSellPerUnit)
      : twoDecimal(product?.stockToCount * product?.priceBuyPerUnit);

    if (product.discountType == "percent") {
      subTotal =
        (Number(subTotal) * (100 - Number(product.discount ?? 0))) / 100;
    } else {
      subTotal = Number(subTotal) - Number(product.discount ?? 0);
    }

    return {
      invProductId: product?.invProductId,
      sku: product?.sku,
      name: product?.name,
      description: product?.description,
      itemType: product?.itemType ?? "DYNAMIC",
      discount: product?.discount ?? 0,
      discountType: product?.discountType ?? "flat",
      stockToCount: product?.stockToCount,
      priceSellPerUnit: product?.priceSellPerUnit,
      priceBuyPerUnit: product?.priceBuyPerUnit,
      invGroupId: product?.invGroupId,
      invFeId: product?.invFeId,
      barcodeId: product?.barcodes?.[0]?.barcodeId,
      salePersonName: product?.salePersonName,
      salePersonId: product?.salePersonId,
      inChargePersonName: product?.inChargePersonName,
      inChargePersonId: product?.inChargePersonId,
      barcodes: JSON.parse(JSON.stringify(product?.barcodes ?? {})),
      subTotal: subTotal,
    };
  });
};
export const FeedBackInput = ({ invalid, error }) => {
  return invalid && error ? (
    <label className="form-label" style={{ color: "red" }}>
      {error}
    </label>
  ) : (
    <></>
  );
};

export const extractPrinterOption = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapPrinter(eachData);
  });

export const mapPrinter = (printer) => {
  return {
    value: printer?.settingId,
    label: printer?.name,
  };
};

export const extractModelOption = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return mapModel(eachData);
  });

export const mapModel = (model) => {
  return {
    value: model?.keyValueId,
    label: model?.label,
  };
};

export const roundingAdjustment = (total, cashIn) => {
  var balance = Number(cashIn ?? 0) - Number(total);

  var result = twoDecimal(balance);
  if (result < 0) {
    return 0;
  }
  return result;
};

export const handleBalance = (cashIn, values, setFieldValue) => {
  cashIn = Number(cashIn ?? 0);

  if (cashIn && cashIn > 0 && values?.value && Number(values?.value ?? 0) > 0) {
    setFieldValue("change", roundingAdjustment(values.value, cashIn));
    setFieldValue("partialBalance", roundingAdjustment(cashIn, values.value));
    if (Number(cashIn ?? 0) < Number(values.value ?? 0)) {
      setFieldValue("billStatus", "PARTIAL");
    } else {
      setFieldValue("billStatus", "PAID");
    }
  }
};

export const twoDecimal = (val) => {
  if (!val) return Number().toFixed(2);

  return Number(val ?? 0.0).toFixed(2);
};

export const tabActive = (history, defaultTab) => {
  return history?.location?.hash?.replace("#", "") == ""
    ? defaultTab
    : history?.location?.hash?.replace("#", "");
};

export const extractPartnerCodeOptions = (responseToParse) =>
  responseToParse?.map((eachData) => {
    return {
      value: eachData?.partnerId,
      label:
        (eachData?.name ?? eachData?.companyName) +
        (eachData?.telNo ? " | " + eachData?.telNo : "") +
        (eachData?.email ? " | " + eachData?.email : ""),
    };
  });

export const InvoiceTitle = (configType = "", invoiceType = "") => {
  const { t } = useTranslation();
  if (invoiceType?.includes("ORDER") && configType?.includes("SALE"))
    return t("Sale Order");
  if (invoiceType?.includes("ORDER") && configType?.includes("PURCHASE"))
    return t("Purchase Order");

  if (invoiceType?.includes("QUOTATION")) return t("Quotation");

  if (
    configType?.includes("SALE") ||
    configType?.includes(formatFilter("INCOME OTHER"))
  )
    return t("Sale / Other Income");
  if (configType?.includes("PURCHASE")) return t("Purchase");
  if (configType == "EXPENSES") return t("Expenses");
  if (configType == "OTHERS") return t("Non-deductable expenses");
  if (configType == formatFilter("CAPITAL EQUITY")) return t("Capital");
  if (configType == formatFilter("CAPITAL DEPTH")) return t("Loan");
  if (configType == formatFilter("CAPITAL DEPTH CREDIT"))
    return t("Loan - credit");
  if (configType == "CASHOUT") return t("Dividen Payment");
  if (configType == "REFUND") return t("Refund");
  if (configType == formatFilter("ASSET FIXED")) return t("Fixed asset");
  if (configType == formatFilter("ASSET CURRENT")) return t("Current asset");
  if (configType == formatFilter("TRANSFER DEBIT")) return t("Transfer debit");
  if (configType == formatFilter("TRANSFER CREDIT"))
    return t("Transfer credit");
  if (configType == "DEPOSIT") return t("Deposit");
  if (configType == formatFilter("CREDIT NOTE")) return t("Credit Note");
  if (configType == formatFilter("DEBIT NOTE")) return t("Debit Note");
  return "";
};

export const CustomerType = (configType = "", invoiceType = "") => {
  if (invoiceType?.includes("ORDER") && configType?.includes("SALE"))
    return "CUSTOMER";
  if (invoiceType?.includes("ORDER") && configType?.includes("PURCHASE"))
    return "SUPPLIER";

  if (invoiceType?.includes("QUOTATION")) return "CUSTOMER";

  if (configType?.includes("SALE") || configType?.includes("INCOME OTHER"))
    return "CUSTOMER";
  if (configType?.includes("PURCHASE")) return "SUPPLIER";

  if (configType?.includes("DEBITSpAcENOTE")) return "SUPPLIER";
};

export const formatPriceBuyPerUnitIncremental = (price) => {
  if (!!!price) return "111111";
  return [...String(Number(price).toFixed(2))?.replaceAll(".", "")]
    .map((n) => (Number(n) == 9 ? 0 : Number(n) + 1))
    .join("")
    ?.padStart(6, "1");
};

export const handlePaymentTransactionCashChange = (
  e,
  index,
  { setFieldValue, values, currentItem }
) => {
  let newData = [...values?.transactions];

  if (Number(e) <= 0) {
    newData = newData.filter(
      (p) => p?.transactionId != currentItem?.transactionId
    );
  } else if (
    newData.filter((p) => p?.transactionId == currentItem?.transactionId)
      .length > 0
  ) {
    newData.map((p) => {
      if (p?.transactionId == currentItem?.transactionId) {
        p.cash = Number(e);
      }
    });
  } else {
    newData.push({
      transactionId: currentItem?.transactionId,
      cash: Number(e),
    });
  }

  setFieldValue("transactions", newData);
  setFieldValue(
    "amount",
    newData.reduce((pV, v) => pV + v?.cash, 0)
  );
};
