import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as Easpro } from "../../../assets/icons/easpro_blue.svg";
import { ReactComponent as Rafiki } from "../../../assets/images/rafiki-girl-boy.svg";
import { postSubscribe } from "../../../services/dataService";
import { handleLoading } from "../../../utils/formUtils";
var qs = require("qs");

const StripeHardwarePuschaseComplete = () => {
  const history = useHistory();
  const sessionId = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }).session_id;

  const [email, setEmail] = useState();
  const [name, setName] = useState();

  const [mutate] = useMutation(
    (sessionId) =>
      postSubscribe({
        path: "/stripe/check-payment-session",
        formData: { sessionId: sessionId },
      }),
    {
      onSuccess: (response) => {
        let emaiName = response?.data?.result.split("##");
        setEmail(emaiName[0]);
        setName(emaiName[1]);
      },
    }
  );

  const msg = () => {
    if (email) {
      return (
        <div>
          <label
            className="subscription-complete-title-status"
            style={{ color: "#000000" }}
          >
            Yeay!{" "}
          </label>
          <br />
          <label className="subscription-complete-title-status">
            Thank you for your purchase.{" "}
          </label>
          <br />
          <label className="subscription-complete-title-description">
            We will contact you shortly for processing update
          </label>
        </div>
      );
    }

    return (
      <div>
        <label className="subscription-complete-title-status">
          Processing..{" "}
        </label>
        <br />
        <label className="subscription-complete-title-description">
          Admin will contact you once transaction success. If it takes longer
          than 3 days please contact admin by providing your email.
        </label>
      </div>
    );
  };

  useEffect(() => {
    handleLoading();
    mutate(sessionId);
    Swal.close();
  }, []);

  return (
    <div className="backgroud">
      <div className="subscription-complete-rectangle" />
      <Rafiki className="subscription-complete-rafiki" />
      <div className="header" />
      <Easpro className="logo-position" />
      <h1 className="subscription-complete-title">{msg()}</h1>
      <div className="footer">
        <span style={{ marginLeft: "5%" }}>
          {" "}
          &#xA9; 2022 xpertgadget.my. All Right Reserved
        </span>
      </div>
    </div>
  );
};

export default StripeHardwarePuschaseComplete;
