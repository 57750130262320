import {
  CButton,
  CCol,
  CFormGroup,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import {
  FloatingOverlay,
  autoUpdate,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import React, { useState } from "react";
import { BiSolidDownArrowAlt } from "react-icons/bi";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { MomentLocale } from "src/localization/momentLocale";
import { onFilterChangeMultipleHandler } from "src/utils/tableUtils";

export const DateRangePickerStockV2 = (props) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    useSelector((state) => state.invoiceDateFilterStart)
  );
  const [endDate, setEndDate] = useState(
    useSelector((state) => state.invoiceDateFilterEnd)
  );
  const moment = MomentLocale();
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `270px`,
            maxHeight: `220px`,
          });
        },
      }),
      offset({
        crossAxis: 100,
        alignmentAxis: 50,
        mainAxis: 10,
      }),
    ],
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleDateConversion = (end, currentDate) => {
    var date;
    if (end) {
      date = moment(currentDate).endOf("day").valueOf();
    } else {
      date = moment(currentDate).startOf("day").valueOf();
    }
    return date;
  };

  return (
    <>
      <button
        className="btn date-range-table-btn"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {`${moment(startDate).format("DD/MM/YY")} - ${moment(endDate).format(
          "DD/MM/YY"
        )}`}
        <FaCaretDown />
      </button>
      {isOpen && (
        <FloatingOverlay
          // lockScroll
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="date-range-table"
        >
          <CRow>
            <CCol sm="12">
              <DateComponent
                {...props}
                end={false}
                setDate={setStartDate}
                currentDate={startDate}
              />
            </CCol>
            <CCol sm="12" style={{ textAlign: "center" }}>
              <BiSolidDownArrowAlt />
            </CCol>
            <CCol sm="12">
              <DateComponent
                {...props}
                end={true}
                setDate={setEndDate}
                currentDate={endDate}
              />
            </CCol>
            <CCol sm="12">
              <CButton
                className="float-right btn-primary"
                onClick={() => {
                  if (props.setStartDateFilter && props.setEndDateFilter) {
                    props.setStartDateFilter(
                      handleDateConversion(false, startDate)
                    );
                    props.setEndDateFilter(handleDateConversion(true, endDate));
                  } else {
                    onFilterChangeMultipleHandler(
                      props?.search,
                      props?.setSearch,
                      [
                        {
                          statusType: props?.statusType,
                          operation: ">=",
                          value: handleDateConversion(false, startDate),
                        },
                        {
                          statusType: props?.statusType,
                          operation: "<=",
                          value: handleDateConversion(true, endDate),
                        },
                      ]
                    );
                  }

                  dispatch({
                    type: "set",
                    invoiceDateFilterStart: new Date(startDate),
                    invoiceDateFilterEnd: new Date(endDate),
                  });
                }}
                // ref={refs.setReference}
                // {...getReferenceProps()}
              >
                apply
              </CButton>
            </CCol>
          </CRow>
        </FloatingOverlay>
      )}
    </>
  );
};

export const DateComponent = (props) => {
  const currentDate = new Date(props?.currentDate);
  const [day, setDay] = useState(currentDate.getDate());
  const [month, setMonth] = useState(currentDate.getMonth());
  const [year, setYear] = useState(currentDate.getYear() + 1900);
  const moment = MomentLocale();

  return (
    <div>
      <CRow className="mx-1">
        <CCol className="px-1">
          <CFormGroup>
            <CLabel htmlFor="ccday">Day</CLabel>
            <CSelect
              custom
              name="ccday"
              id="ccday"
              className="col-input"
              defaultValue={day}
              onChange={(val) => {
                var { value } = val.target;
                setDay(value);
                const d = new Date(year, month, value);

                if (props?.end) {
                  props?.setDate(new Date(moment(d).endOf("day").valueOf()));
                } else {
                  props?.setDate(new Date(moment(d).startOf("day").valueOf()));
                }
              }}
            >
              {(() => {
                const options = [];

                for (let i = 1; i <= 31; i++) {
                  options.push(<option value={i}>{i}</option>);
                }

                return options;
              })()}
            </CSelect>
          </CFormGroup>
        </CCol>
        <CCol className="px-1">
          <CFormGroup>
            <CLabel htmlFor="ccmonth">Month</CLabel>
            <CSelect
              custom
              name="ccmonth"
              id="ccmonth"
              defaultValue={month + 1}
              className="col-input"
              onChange={(val) => {
                const { value } = val.target;
                setMonth(value - 1);
                const d = new Date(year, value - 1, day);

                if (props?.end) {
                  props?.setDate(new Date(moment(d).endOf("day").valueOf()));
                } else {
                  props?.setDate(new Date(moment(d).startOf("day").valueOf()));
                }
              }}
            >
              {(() => {
                const options = [];

                for (let i = 1; i <= 12; i++) {
                  options.push(<option value={i}>{i}</option>);
                }

                return options;
              })()}
            </CSelect>
          </CFormGroup>
        </CCol>
        <CCol className="px-1">
          <CFormGroup>
            <CLabel htmlFor="ccyear">Year</CLabel>
            <CSelect
              custom
              name="ccyear"
              id="ccyear"
              defaultValue={year}
              className="col-input"
              onChange={(val) => {
                const { value } = val.target;
                setYear(value);

                const d = new Date(value, month, day);
                if (props?.end) {
                  props?.setDate(new Date(moment(d).endOf("day").valueOf()));
                } else {
                  props?.setDate(new Date(moment(d).startOf("day").valueOf()));
                }
              }}
            >
              {(() => {
                const options = [];

                for (let i = 2020; i <= new Date().getFullYear(); i++) {
                  options.push(<option value={i}>{i}</option>);
                }

                return options;
              })()}
            </CSelect>
          </CFormGroup>
        </CCol>
      </CRow>
    </div>
  );
};
