export const invoiceStatusConstant = [
  {
    key: "UNPAID",
    label: "UNPAID",
    tooltip: "",
  },
  {
    key: "PAID",
    label: "PAID",
    tooltip: "",
  },
  {
    key: "PARTIAL",
    label: "PARTIAL",
    tooltip: "",
  },
  {
    key: "REFUNDED",
    label: "REFUNDED",
    tooltip: "",
  },
  {
    key: "CLAIM",
    label: "CLAIM",
    tooltip: "",
  },
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "PRINTED",
    label: "PRINTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const creditDebitStatusConstant = [
  {
    key: "PAID",
    label: "RECEIVED",
    tooltip: "",
  },
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const purchaseSaleOrderStatusConstant = [
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "NEW",
    label: "NEW",
    tooltip: "",
  },
  {
    key: "ACCEPTED",
    label: "ACCEPTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const quotationStatusConstant = [
  {
    key: "DRAFT",
    label: "DRAFT",
    tooltip: "",
  },
  {
    key: "NEW",
    label: "NEW",
    tooltip: "",
  },
  {
    key: "ACCEPTED",
    label: "ACCEPTED",
    tooltip: "",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const orderStatusConstant = [
  {
    key: "NEW",
    label: "NEW",
    tooltip: "Order Created",
  },
  {
    key: "UPDATED",
    label: "UPDATED",
    tooltip: "Order updated before payment made",
  },
  {
    key: "PAYMENT_CONFIRMED",
    label: "PAYMENT CONFIRMED",
    tooltip: "Invoice paid",
  },
  {
    key: "PAYMENT_UPDATED",
    label: "PAYMENT UPDATED",
    tooltip: "Invoice paid",
  },
  {
    key: "ORDER_DELIVERED",
    label: "ORDER DELIVERED",
    tooltip: "Items delivered",
  },
  {
    key: "CANCELED",
    label: "CANCELED",
    tooltip: "",
  },
];

export const partnerStatusConstant = [
  {
    key: "ACTIVE",
    label: "ACTIVE",
    tooltip: "",
  },
  {
    key: "RESIGN",
    label: "RESIGN",
    tooltip: "",
  },
  {
    key: "SUSPEND",
    label: "SUSPEND",
    tooltip: "",
  },
  {
    key: "INTERN",
    label: "INTERN",
    tooltip: "",
  },
];
