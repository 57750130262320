export const en = {
  "Transaction Date": "Issue Date",
  Project: "Branch",
  "New Project": "New Branch",
  "Edit Project": "Edit Branch",
  "Add New Project": "Add New Branch",
  "Project Title": "Branch Name",
  "Select Project to use": "Select Branch",
  "It will delete all associated accounts and transactions, Confirm to delete this project?":
    "It will delete all associated accounts and transactions, Confirm to delete this branch?",
  "Need some more data": "Need some more data",
};
