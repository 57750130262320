export const projectConfigType = [
  {
    key: "SALES",
    label: "SALES",
    tooltip: "sale masuk, contoh: jualan harian",
  },
  {
    key: "DEPOSIT",
    label: "DEPOSIT",
    tooltip: "Deposit jualan",
  },
  {
    key: "DEPOSIT REFUND",
    label: "DEPOSIT REFUND",
    tooltip: "Refund deposit jualan",
  },
  {
    key: "INCOME OTHER",
    label: "OTHER INCOME",
    tooltip:
      "Penjualan barang bukan niche company: cth: kereta company atau duit masuk dari entity luar secara sukarela",
  },
  {
    key: "PURCHASE",
    label: "PURCHASE",
    tooltip: "stock/barang mentah dibeli untuk perjualan produk.",
  },
  {
    key: "EXPENSES",
    label: "EXPENSES",
    tooltip: "cost utilities, gaji pekerja, marketing, operation",
  },
  {
    key: "REFUND",
    label: "REFUND",
    tooltip: "pulangan wang kepada pelangan",
  },
  { key: "CASHOUT", label: "DIVIDED PAYMENT", tooltip: "ambilan" },
  {
    key: "CAPITAL EQUITY",
    searchKey: "CAPITALSpAcEEQUITY",
    label: "CAPITAL",
    tooltip: "modal pemilik",
  },
  {
    key: "CAPITAL EQUITY REPAYMENT",
    searchKey: "CAPITALSpAcEEQUITYSpAcEREPAYMENT",
    label: "CAPITAL REPAYMENT",
    tooltip: "modal pemilik (bayaran balik)",
  },
  {
    key: "CAPITAL DEPTH",
    label: "BUSINESS LOAN",
    tooltip: "duit masuk dari pinjaman perniagaan",
  },
  {
    key: "CAPITAL DEPTH REPAYMENT",
    label: "BUSINESS LOAN REPAYMENT",
    tooltip: "bayaran balik pinjaman perniagaan",
  },
  {
    key: "CAPITAL DEPTH CREDIT",
    label: "BUSINESS LOAN (OUT)",
    tooltip: "Memberi pinjaman perniagaan",
  },
  {
    key: "CAPITAL DEPTH CREDIT REPAYMENT",
    label: "BUSINESS LOAN (OUT) REPAYMENT",
    tooltip: "bayaran balik dari pemberian pinjaman perniagaan",
  },
  {
    key: "ASSET FIXED",
    label: "FIXED ASSET",
    tooltip: "rumah, mesin, tanah & etc",
  },
  {
    key: "ASSET CURRENT",
    label: "CURRENT ASSET",
    tooltip: "asset yang senang dicairkan. cth: cash, stock, inventory & etc",
  },
  {
    key: "OTHERS",
    label: "NON-DEDUCTIBLE EXPENSES",
    tooltip:
      "cost yang tidak termasuk dalam pengiraan tax. contoh: cost entertainment",
  },
  {
    key: "TRANSFER DEBIT",
    label: "TRANFER IN",
    tooltip: "akaun tranfer duit masuk dari bank lain",
  },
  {
    key: "TRANSFER CREDIT",
    label: "TRANSFER OUT",
    tooltip: "akaun tranfer keluar ke bank lain",
  },
  {
    key: "CREDIT NOTE",
    label: "CREDIT NOTE",
    tooltip: "akaun credit customer",
  },
  {
    key: "DEBIT NOTE",
    label: "DEBIT NOTE",
    tooltip: "akaun debit supplier",
  },
];
