export const orderType = [
  {
    key: "PURCHASE",
    label: "PURCHASE",
    tooltip: "purhcase order",
  },
  {
    key: "SALE",
    label: "SALE",
    tooltip: "sale order",
  },
];
