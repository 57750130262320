import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { useSelector } from "react-redux";
import { MomentLocale } from "src/localization/momentLocale";
import {
  handleInfoToast,
  handleSucces,
  safeQueryBuilder,
  searchQueryBuilder,
} from "src/utils/formUtils";
import {
  formatFilter,
  tableBasicParams,
  tableBasicParamsWithRow,
} from "src/utils/tableUtils";
import {
  deleteEle,
  deleteEleInventory,
  get,
  getBaseUrl,
  getInventory,
  patch,
  patchInventory,
  post,
} from "./dataService";
import {
  ANAYLYTIC_SALES_TREND_API,
  BANK_API,
  BANK_SYNC_API,
  CATEGORY_API,
  DELETE_FILE,
  DOWNLOAD_CLOSEOUT_REPORT,
  DOWNLOAD_CUSTOMER,
  DOWNLOAD_INVOICES,
  DOWNLOAD_PRODUCT_CONCILIATION,
  DOWNLOAD_STAFF_DETAIL_PERFORMANCE,
  DOWNLOAD_STAFF_PERFORMANCE,
  DOWNLOAD_TEMPLATE,
  FETCH_FILE,
  INVENTORY_API,
  INVENTORY_MIGRATE_API,
  KEY_VALUE_FETCH_ALL_API,
  ORDER_FETCH_ALL_API,
  ORDER_GENERATE_INVOICE,
  PARTNER_API,
  PARTNER_GROUP_API,
  PAYMENT_TRANSACTION_CREATE_API,
  PAYMENT_TRANSACTION_DELETE_API,
  PAYMENT_TRANSACTION_FETCH_ALL_API,
  PAYMENT_TRANSACTION_FETCH_SUMMARY_API,
  PAYMENT_TRANSACTION_MULTIPLE_CREATE_API,
  PAYMENT_TRANSACTION_MULTIPLE_UPDATE_API,
  PAYMENT_TRANSACTION_UPDATE_API,
  PRODUCT_API,
  PRODUCT_AUDIT_API,
  PRODUCT_AUDIT_FETCH_API,
  PRODUCT_AUDIT_FETCH_SUMMARY_API,
  PRODUCT_CONCILIATION_FETCH_ALL_API,
  PRODUCT_CONCILIATION_FETCH_API,
  PRODUCT_DELETE_API,
  PRODUCT_FETCH_BRAND_CATEGORY_API,
  PRODUCT_MERGE_API,
  PRODUCT_SKU_BARCODE_API,
  PRODUCT_SKU_BARCODE_FETCH_API,
  PRODUCT_SKU_BARCODE_UPDATE_API,
  PRODUCT_UPLOAD,
  PRODUCT_VIEW_API,
  PROJECT_API,
  REPORT_BALANCE_SHEET,
  REPORT_CASHFLOW,
  REPORT_CLOSEOUT_REPORT,
  REPORT_CLOSEOUT_REPORT_PRODUCT,
  REPORT_INCOME_STATEMENT,
  REPORT_PRODUCT_CONCILIATION,
  REPORT_STAFF_PERFORMANCE,
  REPORT_STAFF_PERFORMANCE_DETAILS,
  REPORT_TELEGRAM_BRANCH,
  REPORT_TELEGRAM_SALE,
  REPORT_TELEGRAM_STAFF,
  REPORT_TRIAL_BALANCE,
  SETTING_CREATE_API,
  SETTING_FETCH_ALL_API,
  SETTING_UPDATE_API,
  TRANSACTION_DELETE_API,
  TRANSACTION_FETCH_API,
  TRANSACTION_NEW_API,
  TRANSACTION_UPDATE_API,
  UPLOAD_TEMPLATE,
  USER_CURRENT_API,
} from "./pathRequest";
var FileSaver = require("file-saver");

export const UserInfo = (callback) => CompanyInfo(callback);

export const CompanyInfo = (callback) =>
  useQuery(
    ["USER_CURRENT_API"],
    () =>
      get({
        path: USER_CURRENT_API,
      }),
    {
      keepPreviousData: true,
      cacheTime: 600000,
      staleTime: 600000,
      retry: false,
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
    }
  );

export const ProjectList = (callback) => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    ["PROJECT_LIST"],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PROJECT_API,
            tableBasicParamsWithRow(0, "createdDate", "false", 1000)
          ),
          [
            { key: "configType", operation: ":", value: "PROJECT" },
            { key: "id", operation: ":", value: formatFilter(projects) },
          ]
        ),
      }),
    {
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
    }
  );
};

export const ProjectListMutate = () =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PROJECT_API,
          tableBasicParamsWithRow(0, "createdDate", "false", 1000)
        ),
        [
          { key: "configType", operation: ":", value: "PROJECT" },
          { key: "projectTitle", operation: "~", value: search },
        ]
      ),
    })
  );

export const CategoryListMutate = (extra) =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          CATEGORY_API,
          tableBasicParamsWithRow(0, "createdDate", "false", 1000)
        ),
        [{ key: "category", operation: "~", value: search }].concat(extra ?? [])
      ),
    })
  );

export const CategoryListByProject = ({ page, sortBy, isAsc, projectId }) =>
  useQuery(["CATEGORY_API", { page, sortBy, isAsc, projectId }], () =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          CATEGORY_API,
          tableBasicParamsWithRow(page, sortBy, isAsc, 50)
        ),
        [
          {
            key: "project",
            operation: ":",
            value: projectId?.replaceAll("-", "DasH"),
          },
        ]
      ),
    })
  );

export const CategoryListByProjectMutate = (search, extra) =>
  useMutation((projectId) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          CATEGORY_API,
          tableBasicParamsWithRow(0, "createdDate", "false", 1000)
        ),
        [
          {
            key: "project",
            operation: ":",
            value:
              search?.replaceAll("-", "DasH") ||
              projectId?.replaceAll("-", "DasH"),
          },
        ].concat(extra ?? [])
      ),
    })
  );

export const BankList = () =>
  useQuery("BANK_API", () =>
    get({
      path: BANK_API,
    })
  );

export const CreateTransaction = (callback) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(
    (form) =>
      post({
        path: TRANSACTION_NEW_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        if (callback) {
          callback();
        } else {
          queryCache.refetchQueries("PRODUCT_VIEW_API");
          queryCache.refetchQueries("INVOICE_VIEW_API");
          queryCache.invalidateQueries("BANK_API");
          handleSucces(t("saved"), t("success"));
        }
      },
    }
  );
};

export const GetTransaction = (transactionId, callBack) => {
  return useQuery(
    ["TRANSACTION_FETCH_API", transactionId],
    () =>
      get({
        path: safeQueryBuilder(TRANSACTION_FETCH_API, { id: transactionId }),
      }),
    {
      onSuccess: (transactionInfo) => {
        callBack(transactionInfo);
      },
      cacheTime: 60000,
      refetchOnWindowFocus: false,
    }
  );
};

export const FetchTransaction = () => {
  return useMutation((transactionId) =>
    get({
      path: safeQueryBuilder(TRANSACTION_FETCH_API, { id: transactionId }),
    })
  );
};

export const UpdateTransaction = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: TRANSACTION_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const DeleteTransaction = (transactionId) =>
  useMutation(
    () =>
      deleteEle({
        path: safeQueryBuilder(TRANSACTION_DELETE_API, { id: transactionId }),
      }),
    {
      onSuccess: () => {
        handleSucces(t("deleted"), t("success"));
      },
    }
  );

export const PaymentTransactionList = ({
  page,
  sortBy,
  isAsc,
  startDateFilter,
  endDateFilter,
  transactionId,
  type = "transaction",
  extra,
  row = 50,
  execute = true,
}) =>
  useQuery(
    [
      "PAYMENT_TRANSACTION_FETCH_ALL_API",
      {
        page,
        sortBy,
        isAsc,
        startDateFilter,
        endDateFilter,
        transactionId,
        ...extra,
      },
    ],
    () => {
      if (!execute) return Promise.resolve();

      return get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PAYMENT_TRANSACTION_FETCH_ALL_API,
            Object.assign(tableBasicParamsWithRow(page, sortBy, isAsc, row), {
              id: transactionId,
              type: type,
            })
          ),
          [
            { key: "paymentDate", operation: ">=", value: startDateFilter },
            { key: "paymentDate", operation: "<=", value: endDateFilter },
          ].concat(extra ?? [])
        ),
      });
    }
  );

export const PaymentTransactionListSummary = ({
  startDateFilter,
  endDateFilter,
  transactionId,
  type = "transaction",
  extra,
}) =>
  useQuery(
    [
      "PAYMENT_TRANSACTION_FETCH_SUMMARY_API",
      {
        startDateFilter,
        endDateFilter,
        transactionId,
        ...extra,
      },
    ],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(PAYMENT_TRANSACTION_FETCH_SUMMARY_API, {
            id: transactionId,
            type: type,
          }),
          [
            { key: "paymentDate", operation: ">=", value: startDateFilter },
            { key: "paymentDate", operation: "<=", value: endDateFilter },
          ].concat(extra ?? [])
        ),
      })
  );

export const CreatePaymentTransactionMultiple = (callback) =>
  useMutation(
    (form) =>
      post({
        path: PAYMENT_TRANSACTION_MULTIPLE_CREATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const CreatePaymentTransaction = (callback) =>
  useMutation(
    (form) =>
      post({
        path: PAYMENT_TRANSACTION_CREATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const UpdatePaymentTransaction = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: PAYMENT_TRANSACTION_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const UpdatePaymentTransactionMultiple = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: PAYMENT_TRANSACTION_MULTIPLE_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => {
        callback();
      },
    }
  );

export const DeletePayment = ({ url = PAYMENT_TRANSACTION_DELETE_API }) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return useMutation(
    (paymentTransactionId) =>
      deleteEle({
        path: safeQueryBuilder(url, {
          id: paymentTransactionId,
        }),
      }),
    {
      onSuccess: () => {
        queryCache.removeQueries("PAYMENT_TRANSACTION_FETCH_ALL_API", {
          lazy: true,
        });
        queryCache.removeQueries("TRANSACTION_FETCH_API", { lazy: true });
        queryCache.refetchQueries("TRANSACTION_API", { lazy: true });
        handleSucces(t("saved"), t("success"));
      },
    }
  );
};

export const GetBankList = () =>
  useQuery(
    "BANK_API",
    () =>
      get({
        path: BANK_API,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

export const BankSync = () => {
  const queryCache = useQueryCache();
  return useMutation(
    () =>
      get({
        path: BANK_SYNC_API,
      }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("BANK_API", { lazy: true });
      },
    }
  );
};

export const SearchCustomerMutate = (type) =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PARTNER_API,
          tableBasicParamsWithRow(1, "name", false, 20)
        ),
        [
          { key: "email", operation: "~", value: search },
          { key: "telNo", operation: "~", value: search },
          { key: "name", operation: "~", value: search },
          { key: "shortcode", operation: "~", value: search },
          { key: "type", operation: ":", value: type },
        ]
      ),
    })
  );

export const SearchStaffMutate = () =>
  useMutation((search) =>
    get({
      path: searchQueryBuilder(
        safeQueryBuilder(PARTNER_API, tableBasicParams(1, "name", false)),
        [
          { key: "email", operation: "~", value: search },
          { key: "telNo", operation: "~", value: search },
          { key: "name", operation: "~", value: search },
          { key: "type", operation: ":", value: "STAFF" },
          {
            key: "status",
            operation: ":",
            value: formatFilter([
              { value: "ACTIVE" },
              { value: "SUSPEND" },
              { value: "INTERN" },
            ]),
          },
        ]
      ),
    })
  );

export const PartnerGroupList = (callback) =>
  useQuery(
    ["GROUP_API"],
    () =>
      get({
        path: PARTNER_GROUP_API,
      }),
    {
      onSuccess: (response) => callback(response),
    }
  );

export const DeleteProduct = () =>
  useMutation((productId) =>
    deleteEleInventory({
      path: safeQueryBuilder(PRODUCT_DELETE_API, { id: productId }),
    })
  );

export const MergeProduct = () =>
  useMutation((form) =>
    patchInventory({
      path: PRODUCT_MERGE_API,
      formData: form,
    })
  );

export const SearchProduct = (extra) => {
  const projects = useSelector((state) => state?.appContext);

  return useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PRODUCT_API,
          tableBasicParamsWithRow(1, "name", false, 20)
        ),
        [
          {
            key: "name",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "brand",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "sku",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "extraReference",
            operation: ":",
            value: formatFilter(projects),
          },
        ].concat(extra ?? [])
      ),
    })
  );
};

export const SearchProductBySku = () => {
  const projects = useSelector((state) => state?.appContext);

  return useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PRODUCT_API,
          tableBasicParamsWithRow(1, "name", false, 20)
        ),
        [
          {
            key: "name",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "sku",
            operation: "~",
            value: search ? formatFilter(search) : " ",
          },
          {
            key: "itemType",
            operation: ":",
            value: "STATIC",
          },
          {
            key: "extraReference",
            operation: ":",
            value: formatFilter(projects),
          },
        ]
      ),
    })
  );
};

export const SearchProductByBarcode = (extra) =>
  useMutation((search) =>
    getInventory({
      path: searchQueryBuilder(
        safeQueryBuilder(
          PRODUCT_SKU_BARCODE_API,
          tableBasicParams(1, "barcodeId", false)
        ),
        [
          {
            key: "barcodeId",
            operation: ":",
            value: search ? search.replaceAll(" ", "SpAcE") : " ",
          },
        ].concat(extra ?? [])
      ),
    })
  );

export const GetBarcode = (invProductSkuId) =>
  useQuery(["PRODUCT_SKU_BARCODE_FETCH_API", invProductSkuId], () => {
    if (!invProductSkuId) return;

    return getInventory({
      path: safeQueryBuilder(PRODUCT_SKU_BARCODE_FETCH_API, {
        id: invProductSkuId,
      }),
    });
  });

export const UpdateBarcode = () =>
  useMutation((form) =>
    patchInventory({
      path: PRODUCT_SKU_BARCODE_UPDATE_API,
      formData: form,
    })
  );

export const Print = (url) => {
  const { t } = useTranslation();
  return useMutation(
    (form) =>
      Axios.post(url, form, {
        timeout: 2000,
      }),
    {
      onSuccess: () => {
        handleSucces("Print " + t("success"), t("success"));
      },
    }
  );
};

export const GenerateReportIncomeStatement = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_INCOME_STATEMENT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportCashflow = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_CASHFLOW, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportTrialBalance = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_TRIAL_BALANCE, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportBalanceSheet = () =>
  useMutation(({ projectId, startDate, endDate, cogsDirectPurchase }) =>
    get({
      path: safeQueryBuilder(REPORT_BALANCE_SHEET, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        cogsDirectPurchase: cogsDirectPurchase,
      }),
    })
  );

export const GenerateReportCloseout = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_CLOSEOUT_REPORT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateReportCloseoutProduct = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_CLOSEOUT_REPORT_PRODUCT, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateReportTelegramSale = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_TELEGRAM_SALE, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateReportTelegramStaff = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_TELEGRAM_STAFF, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateReportTelegramBranch = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(REPORT_TELEGRAM_BRANCH, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateSalesTrend = () =>
  useMutation(({ projectId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(ANAYLYTIC_SALES_TREND_API, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
      }),
    })
  );

export const GenerateReportStaffPerformance = () =>
  useMutation(({ projectId, startDate, endDate, self }) =>
    get({
      path: safeQueryBuilder(REPORT_STAFF_PERFORMANCE, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        self: self,
      }),
    })
  );

export const GenerateReportStaffPerformanceDetails = () =>
  useMutation(({ projectId, startDate, endDate, staffId }) =>
    get({
      path: safeQueryBuilder(REPORT_STAFF_PERFORMANCE_DETAILS, {
        projectId: projectId,
        startDate: startDate,
        endDate: endDate,
        staffId: staffId,
      }),
    })
  );

export const GenerateReportProductConciliation = () => {
  var base = getBaseUrl("inventory");
  const moment = MomentLocale();

  return useMutation(({ inventoryId, startDate, endDate }) =>
    get({
      path: safeQueryBuilder(base + REPORT_PRODUCT_CONCILIATION, {
        startDate: startDate,
        endDate: endDate,
        inventoryId: inventoryId,
      }),
    })
  );
};

export const OrderGenerateInvoice = () =>
  useMutation((id) =>
    get({
      path: safeQueryBuilder(ORDER_GENERATE_INVOICE, { id: id }),
    })
  );

export const GetOrder = ({
  page,
  sortBy,
  isAsc,
  startDateFilter,
  endDateFilter,
  orderType,
  filterStatusType,
  statusType,
}) =>
  useQuery(
    [
      "ORDER_FETCH_ALL_API",
      {
        page,
        sortBy,
        isAsc,
        startDateFilter,
        endDateFilter,
        orderType,
        statusType,
      },
    ],
    () =>
      get({
        path: searchQueryBuilder(
          safeQueryBuilder(
            ORDER_FETCH_ALL_API,
            tableBasicParamsWithRow(page, sortBy, isAsc, 50)
          ),
          [
            { key: "createdDate", operation: "=>", value: endDateFilter },
            { key: "createdDate", operation: "<=", value: startDateFilter },
            { key: "type", operation: ":", value: orderType },
            { key: filterStatusType, operation: ":", value: statusType },
          ]
        ),
      }),
    {
      retry: false,
    }
  );

export const OrderSetting = (callback) => SettingConfig(callback);

export const SettingConfig = (callback) =>
  useQuery(
    "SETTING_FETCH_ALL_API",
    () =>
      get({
        path: safeQueryBuilder(SETTING_FETCH_ALL_API),
      }),
    {
      onSuccess: (data) => callback(data),
      refetchOnWindowFocus: false,
      cacheTime: 60000,
    }
  );

export const SettingConfigMutate = (callback) =>
  useMutation(
    () =>
      get({
        path: safeQueryBuilder(SETTING_FETCH_ALL_API),
      }),
    {
      onSuccess: (data) => callback && callback(data),
      refetchOnWindowFocus: false,
    }
  );

export const CreateSetting = (callback) =>
  useMutation(
    (form) =>
      post({
        path: SETTING_CREATE_API,
        formData: form,
      }),
    {
      onSuccess: () => callback(),
    }
  );

export const UpdateSetting = (callback) =>
  useMutation(
    (form) =>
      patch({
        path: SETTING_UPDATE_API,
        formData: form,
      }),
    {
      onSuccess: () => callback(),
    }
  );

export const ProductBrandCategory = () => {
  return useQuery(
    ["PRODUCT_FETCH_BRAND_CATEGORY_API"],
    () =>
      getInventory({
        path: PRODUCT_FETCH_BRAND_CATEGORY_API,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const InventoryMigrateMutation = () => {
  return useMutation(
    (form) =>
      patchInventory({
        path: INVENTORY_MIGRATE_API,
        formData: form,
      }),
    {
      retry: false,
    }
  );
};

export const StockAuditList = ({ page, sortBy, isAsc }) => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    ["PRODUCT_AUDIT_API", { page, sortBy, isAsc }],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PRODUCT_AUDIT_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ]
        ),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const InventoryList = ({ page, sortBy, isAsc }) => {
  const projects = useSelector((state) => state?.appContext);
  return useQuery(
    ["INVENTORY_API", { page, sortBy, isAsc }],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            INVENTORY_API,
            tableBasicParams(page, sortBy, isAsc)
          ),
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ]
        ),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const InventoryListMutate = (isGlobal) => {
  const projects = useSelector((state) => state?.appContext);
  return useMutation(
    (search) =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(INVENTORY_API, { isGlobal: isGlobal }),
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ].concat(search)
        ),
      }),
    {
      retry: false,
    }
  );
};

export const ProductList = ({
  page,
  sortBy,
  isAsc,
  isAll,
  inventoryId,
  projects,
  search,
  extraInfo = false,
  isGlobal = false,
  extra,
}) =>
  useQuery(
    [
      "PRODUCT_VIEW_API",
      { extraInfo, page, sortBy, isAsc, isAll, projects, ...search },
    ],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PRODUCT_VIEW_API,
            Object.assign(tableBasicParamsWithRow(page, sortBy, isAsc, 50), {
              id: isAll ? null : inventoryId,
              extraInfo: extraInfo,
              isGlobal: isGlobal,
            })
          ),
          [
            {
              key: "extraReference",
              operation: ":",
              value: formatFilter(projects),
            },
          ]
            .concat(extra ?? [])
            .concat(search ?? [])
        ),
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

export const ProductCategoryMutate = () =>
  useMutation((search) => {
    // console.log(search);
    return getInventory({
      path: searchQueryBuilder(KEY_VALUE_FETCH_ALL_API, [
        { key: "klass", operation: ":", value: "InvProductEntity" },
        { key: "label", operation: "~", value: search },
      ]),
    });
  });

export const ConciliationList = ({ productId, page, sortBy, isAsc }) =>
  useQuery(
    ["PRODUCT_CONCILIATION_FETCH_ALL_API", { productId, page, sortBy, isAsc }],
    () =>
      getInventory({
        path: searchQueryBuilder(
          safeQueryBuilder(
            PRODUCT_CONCILIATION_FETCH_ALL_API,
            tableBasicParamsWithRow(page, sortBy, isAsc, 50)
          ),
          [
            {
              key: "product",
              operation: ":",
              value: productId.replaceAll("-", "DasH"),
            },
          ]
        ),
      })
  );

export const GetConciliation = (invProductConciliationId) =>
  useQuery("PRODUCT_CONCILIATION_FETCH_API", () =>
    getInventory({
      path: PRODUCT_CONCILIATION_FETCH_API + "?id=" + invProductConciliationId,
    })
  );

export const DownloadTransactionTemplate = () => {
  var base = getBaseUrl("eas");

  return useMutation(() =>
    axios
      .get(base + DOWNLOAD_TEMPLATE, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, "transaction-template.csv");
      })
  );
};

export const UploadTransactionTemplate = () => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation(
    (form) => instance.post(base + UPLOAD_TEMPLATE, form, { timeout: 600000 }),
    {
      onSuccess: () => {
        handleSucces(t("saved"), t("success"));
        queryCache.invalidateQueries("CATEGORY_API", { lazy: true });
      },

      onError: (error) => {
        var errorBody = error?.response?.data?.response;
        var message = errorBody?.status?.httpMessage;
        handleInfoToast(message);
      },
    }
  );
};

export const UploadBulkProduct = () => {
  const queryCache = useQueryCache();
  var base = getBaseUrl("inventory");
  const { t } = useTranslation();
  const instance = axios.create();

  return useMutation(
    (form) => instance.post(base + PRODUCT_UPLOAD, form, { timeout: 600000 }),
    {
      onSuccess: () => {
        handleSucces(t("saved"), t("success"));
        queryCache.invalidateQueries("PRODUCT_API", { lazy: true });
      },

      onError: (error) => {
        // console.log(error);
        var errorBody = error?.response?.data?.response;
        var message = errorBody?.status?.httpMessage;
        handleInfoToast(message);
      },
    }
  );
};

export const UploadImage = (url) => {
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation((form) => instance.patch(base + url, form), {
    onSuccess: () => {
      handleSucces(t("saved"), t("success"));
    },

    onError: (error) => {
      var errorBody = error?.response?.data?.response;
      var message = errorBody?.status?.httpMessage;
      handleInfoToast(message);
    },
  });
};

export const DownloadInvoices = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation(({ projectId, startDate, endDate }) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_INVOICES, {
          projectId: projectId,
          startDate: startDate,
          endDate: endDate,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `invoice-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const DownloadCustomerList = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation(() =>
    axios
      .get(safeQueryBuilder(base + DOWNLOAD_CUSTOMER), {
        responseType: "blob",
      })
      .then((response) => {
        var name = `customer-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const DownloadStaffPerformance = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation((form) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_STAFF_PERFORMANCE, {
          startDate: form?.startDate,
          endDate: form?.endDate,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `staff-performance-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const DownloadStaffPerformanceDetails = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation((form) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_STAFF_DETAIL_PERFORMANCE, {
          startDate: form?.startDate,
          endDate: form?.endDate,
          projectId: form?.projectId,
          staffId: form?.staffId,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `staff-performance-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const DownloadCloseoutReport = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation((form) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_CLOSEOUT_REPORT, {
          startDate: form?.startDate,
          endDate: form?.endDate,
          projectId: form?.projectId,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `closeout-report-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const DownloadProductConciliation = () => {
  var base = getBaseUrl("inventory");
  const moment = MomentLocale();

  return useMutation((form) =>
    axios
      .get(
        safeQueryBuilder(base + DOWNLOAD_PRODUCT_CONCILIATION, {
          startDate: form?.startDate,
          endDate: form?.endDate,
          inventoryId: form?.inventoryId,
        }),
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        var name = `product_conciliation-${moment().format("YYYY-MM-DD")}.csv`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
  );
};

export const UploadFile = (url) => {
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  var base = getBaseUrl("eas");
  const instance = axios.create();

  return useMutation(
    (form) => instance.post(base + url, form, { timeout: 600000 }),
    {
      onSuccess: () => {
        handleSucces(t("saved upload attachment"), t("success"));
        queryCache.invalidateQueries("CATEGORY_API", { lazy: true });
      },

      onError: (error) => {
        var errorBody = error?.response?.data?.response;
        var message = errorBody?.status?.httpMessage;
        handleInfoToast(message);
      },
    }
  );
};

export const DownloadFile = () => {
  var base = getBaseUrl("eas");
  const moment = MomentLocale();

  return useMutation((fileId) =>
    axios
      .get(safeQueryBuilder(base + FETCH_FILE + "?id=" + fileId), {
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"]
          .split("filename=")[1]
          .split(".")[0];
        let e = response.headers["content-disposition"].split(".");
        let extension = e[e.length - 1].split(";")[0];
        var name = `${filename}.${extension}`;
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, name);
      })
      .finally(() => {
        // console.log("finish");
      })
  );
};

export const DeleteFile = () =>
  useMutation((fileId) =>
    deleteEle({
      path: safeQueryBuilder(DELETE_FILE, { id: fileId }),
    })
  );

export const AuditListMutate = (func) =>
  useMutation(
    (id) =>
      getInventory({
        path: safeQueryBuilder(PRODUCT_AUDIT_FETCH_API, { id: id }),
      }),
    {
      onSuccess: (res) => {
        if (func) func(res);
      },
    }
  );

export const AuditListSummaryMutate = () =>
  useMutation((id) =>
    getInventory({
      path: safeQueryBuilder(PRODUCT_AUDIT_FETCH_SUMMARY_API, { id: id }),
    })
  );
