import moment from "moment-timezone";
import "moment/locale/en-au";
import "moment/locale/ms";
import { useDispatch, useSelector } from "react-redux";
import { CompanyInfo } from "src/services/queryService";

export const MomentLocale = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state?.appContext);
  moment.tz("Asia/Kuala_Lumpur");

  CompanyInfo((response) => {
    const { locale } = response?.data;
    moment.locale(!locale || "en".includes(locale) ? "en-au" : locale);

    if (response?.data?.projectPrivilege?.[0] && projects == null) {
      dispatch({
        type: "set",
        appContext:
          [
            {
              value: response?.data?.projectPrivilege?.[0]?.projectId,
              label: response?.data?.projectPrivilege?.[0]?.projectTitle,
              shortcode: response?.data?.projectPrivilege?.[0]?.shortcode,
            },
          ] ?? [],
      });
    }
  });
  return moment;
};

export const getStartDate = (start, end) => {
  const s = moment(start);
  const e = moment(end);
  if (moment.duration(e.diff(s)).asDays() < 93) return s;

  return e.subtract(1, "months").startOf("month");
};
