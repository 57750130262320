import { CCol, CContainer, CLabel, CRow } from "@coreui/react";
import { canUseDOM } from "@coreui/react/src/utils/helper";
import moment from "moment";
import { default as React, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { get, getBaseUrl, post } from "src/services/dataService";
import {
  SHORT_LINK_FETCH_API,
  TRANSACTION_FETCH_PUBLIC_API,
} from "src/services/pathRequest";
import { safeQueryBuilder, twoDecimal } from "src/utils/formUtils";
import Swal from "sweetalert2";

const InvoicePublic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [trxId, setTrxId] = useState();
  const [getLongLink] = useMutation(
    (form) =>
      post({
        path: SHORT_LINK_FETCH_API,
        formData: form,
      }),
    {
      onSettled: Swal.close(),
    }
  );

  const componentToPrintRef = useRef(null);
  const [products, setProducts] = useState(null);

  const handlePrint = (e) => {
    e.preventDefault();
    canUseDOM && print();
  };

  const print = useReactToPrint({
    content: () => componentToPrintRef.current,
    documentTitle: history.location?.state?.data?.billId
      ? `bill_#${history.location?.state?.data?.billId}`
      : Date.now(),
  });

  const [getTrxInfo, { data: transactionInfo }] = useMutation(
    (transactionId) =>
      get({
        path: safeQueryBuilder(TRANSACTION_FETCH_PUBLIC_API, {
          id: transactionId,
        }),
      }),
    {
      onSettled: Swal.close(),
    }
  );

  useEffect(() => {
    getLongLink({ shortLink: document.URL }).then((response) => {
      var longLink = response?.data?.result;

      const url = new URL(longLink);
      const searchParams = url.searchParams;

      var queryType = searchParams.get("t");
      var transactionId = searchParams.get("i");
      setTrxId(transactionId);
      if (queryType == "bill") {
        getTrxInfo(transactionId).then((response) => {
          setProducts(response?.data?.products);
        });
      }
    });
  }, []);

  var to = transactionInfo?.data?.partner;
  var billType = transactionInfo?.data?.billType;
  var configType = transactionInfo?.data?.configType;
  var billId = transactionInfo?.data?.billIdPrefix;
  var billStatus = transactionInfo?.data?.billStatus;
  var createdDate = transactionInfo?.data?.createdDate;
  var createdBy = transactionInfo?.data?.createdBy;
  var defaultTnC = transactionInfo?.data?.defaultTnC;
  var defaultFooter = transactionInfo?.data?.defaultFooter;
  var transactionDate = transactionInfo?.data?.transactionDate;
  var dueDate = transactionInfo?.data?.dueDate;

  var notes = transactionInfo?.data?.description;
  var total = transactionInfo?.data?.value;
  var balance = transactionInfo?.data?.partialBalance;
  var billReferenceId = transactionInfo?.data?.billReferenceId;
  var isDebit = transactionInfo?.data?.isDebit;
  var branchTitle = transactionInfo?.data?.projectTitle;
  var company = transactionInfo?.data?.company;
  var telNo = company?.telNo;
  var email = company?.companyEmail ?? transactionInfo?.data?.ownerEmail;

  var address = company?.companyAddress?.split(",");
  var delivery = transactionInfo?.data?.delivery;
  var deposit =
    transactionInfo?.data?.configType == "DEPOSIT"
      ? transactionInfo?.data?.cash
      : transactionInfo?.data?.deposit;
  var discount = products
    ?.map((m) => {
      if (["delivery"]?.includes(m?.name?.toLowerCase())) return;

      var subTotal =
        m?.stockToCount * isDebit ? m?.priceSellPerUnit : m?.priceBuyPerUnit;

      if (m?.discountType == "percent" && m?.discount) {
        return (Number(subTotal) * Number(m?.discount ?? 0)) / 100;
      } else if (m?.discountType == "flat" && m?.discount) {
        return Number(m?.discount ?? 0);
      }
      return 0;
    })
    .reduce((p, c) => (p ?? 0) + (c ?? 0), 0);

  return transactionInfo?.data?.billId ? (
    <CContainer
      style={{
        maxWidth: "900.70px",
      }}
    >
      <div
        className="invoice-box mb-5 pt-1"
        ref={componentToPrintRef}
        style={{
          backgroundColor: "white",
        }}
      >
        <CRow className="mx-3 my-3">
          <CCol>
            <CRow className="mb-3">
              <CCol sm="9">
                <CRow>
                  <CCol
                    sm="4"
                    className="text-center"
                    style={{ display: "contents" }}
                  >
                    <img
                      style={{
                        maxHeight: "95px",
                        maxWidth: "170px",
                        marginBottom: "5px",
                      }}
                      src={getBaseUrl("eas") + `/image/logo/fetch?id=${trxId}`}
                      alt={company?.companyName}
                    />
                  </CCol>
                  <CCol>
                    {company?.companyName ? (
                      <h4 style={{ color: "black", lineHeight: "17px" }}>
                        {company?.companyName}{" "}
                        <span
                          style={{ fontSize: "12px" }}
                          className="text-muted"
                        >
                          {company?.ssm}
                        </span>
                      </h4>
                    ) : null}
                    {branchTitle && (
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "17px",
                        }}
                        className="my-0 font-weight-bold"
                      >
                        {branchTitle}
                      </p>
                    )}
                    {address && (
                      <p
                        style={{
                          whiteSpace: "pre-line",
                          lineHeight: "17px",
                        }}
                        className="my-0 font-weight-bold"
                      >
                        {address}
                      </p>
                    )}

                    {telNo && (
                      <p
                        className="my-0 font-weight-bold"
                        style={{ lineHeight: "17px" }}
                      >
                        Tel: {telNo}
                      </p>
                    )}
                    {email && (
                      <p
                        className="my-0 font-weight-bold"
                        style={{ lineHeight: "17px" }}
                      >
                        {email}
                      </p>
                    )}
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="3">
                <CRow>
                  <CCol className="px-0">
                    <h4 className="float-right" style={{ color: "black" }}>
                      {billType?.includes("INVOICE") && configType == "PURCHASE"
                        ? "PURCHASE INVOICE"
                        : billType?.includes("INVOICE") &&
                          billType != "QUOTATION" &&
                          configType != "DEPOSIT"
                        ? "INVOICE"
                        : billType?.includes("INVOICE") &&
                          billType != "QUOTATION" &&
                          configType == "DEPOSIT"
                        ? "DEPOSIT"
                        : billType?.includes("ORDER") &&
                          configType == "PURCHASE"
                        ? "PURCHASE ORDER"
                        : billType?.includes("ORDER")
                        ? "SALE ORDER"
                        : "QUOTATION"}
                    </h4>
                  </CCol>
                </CRow>
                <CRow className="flex-column">
                  <CCol className="px-0">
                    <p className="float-right font-weight-bold">#{billId}</p>
                  </CCol>
                  <CCol className="px-0">
                    <p className="float-right">
                      By: {createdBy?.split("@")[0]?.substring(0, 8)}
                    </p>
                  </CCol>
                  <CCol>
                    <CRow>
                      <CCol
                        className="font-weight-bold "
                        style={{
                          border: "1px solid #d8dbe0",
                          textAlign: "center",
                        }}
                      >
                        <label
                          style={{
                            color: billStatus == "PAID" ? "green" : "",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          {billStatus}
                        </label>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CRow>
                  <CCol sm="8 p-2" style={{ border: "1px solid #d8dbe0" }}>
                    <CRow>
                      <CCol>
                        <p className="mb-0">
                          {to?.name && to?.companyName
                            ? `${to?.name} (${to?.companyName})`
                            : to?.name || to?.companyName}
                        </p>
                        <p className="mb-0">{to?.address}</p>
                        <p>{to?.email}</p>
                        <p className="mb-0">TEL: {to?.telNo}</p>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol sm="4">
                    <CRow>
                      <CCol>
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">
                            {" "}
                            <label className="float-right">
                              {t("Issue Date")}
                            </label>
                          </CCol>
                          <CCol className="font-weight-bold">
                            <label className="float-right">
                              {transactionDate
                                ? moment(transactionDate).format("DD/MM/YYYY")
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">
                            <label className="float-right">
                              {t("Due Date")}
                            </label>
                          </CCol>
                          <CCol className="font-weight-bold ">
                            <label className="float-right">
                              {dueDate
                                ? moment(dueDate).format("MM/DD/YYYY")
                                : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CRow className="mb-1">
                          <CCol sm="6 text-muted">
                            <label className="float-right">{t("Amount")}</label>
                          </CCol>
                          <CCol className="font-weight-bold">
                            <label className="float-right">
                              {total ? "RM" + twoDecimal(total) : "-"}
                            </label>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      {/* <CCol sm="8">
                            <CRow className="mb-1">
                              <CCol sm="2 text-muted">
                                {isInvoice ? t("Payment Status") : t("Status")}
                              </CCol>
                              <CCol className="font-weight-bold">
                                <label>{billStatus}</label>
                              </CCol>
                            </CRow>
                          </CCol> */}
                      <CCol>
                        <CRow className="mb-1">
                          {/* {isInvoice ? (
                                <>
                                  <CCol sm="6 text-muted">
                                    <label>{t("Paid Amount")}</label>
                                  </CCol>
                                  <CCol className="font-weight-bold">
                                    <label>
                                      {total ? "RM" + twoDecimal(cash) : "-"}
                                    </label>
                                  </CCol>
                                </>
                              ) : (
                                ""
                              )} */}
                        </CRow>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-5 mb-3">
              <CCol>
                <CRow
                  className="mb-2 font-weight-bold"
                  style={{
                    background: "#B9B9B9",
                    color: "black",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <CCol sm="8" className="mt-1">
                    <h5 className="align-middle">{t("Items")}</h5>
                  </CCol>
                  <CCol sm="2" className="mt-1">
                    <h5 className="float-right align-middle">
                      {t("Price/unit")}
                    </h5>
                  </CCol>
                  <CCol className="mt-1">
                    <h5 className="float-right align-middle">
                      {t("Sub Price")}
                    </h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    {products?.map((product, index) => {
                      if (
                        ["discount", "delivery"]?.includes(
                          product?.name?.toLowerCase()
                        )
                      )
                        return;
                      return (
                        <CRow
                          className="page-break-before"
                          style={{
                            marginBottom: "2px",
                            borderBottom: "1px solid",
                            borderBottomColor: "#DCDCDC",
                          }}
                          key={product.name}
                        >
                          <CCol sm="8">
                            <CRow>
                              <CCol className="font-weight-bold">
                                <label>
                                  {index + 1}. {product.name}
                                </label>{" "}
                                <label className="text-muted">
                                  x {product?.stockToCount}
                                </label>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol
                                style={{ whiteSpace: "pre-line" }}
                                className="ml-4"
                              >
                                <p>
                                  {product?.barcodes?.map((d) => {
                                    if (!d?.barcodeId) return;
                                    return d?.barcodeId + " ";
                                  }) ?? null}
                                </p>
                                <label>{product.description ?? ""}</label>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol sm="2">
                            <label className="float-right">
                              RM
                              {twoDecimal(
                                (isDebit &&
                                  !["DEBIT NOTE"].includes(configType)) ||
                                  ["CREDIT NOTE"].includes(configType)
                                  ? product?.priceSellPerUnit
                                  : product?.priceBuyPerUnit
                              )}
                            </label>
                          </CCol>
                          <CCol>
                            <label className="float-right">
                              RM
                              {twoDecimal(
                                product?.stockToCount *
                                  ((isDebit &&
                                    !["DEBIT NOTE"].includes(configType)) ||
                                  ["CREDIT NOTE"].includes(configType)
                                    ? product?.priceSellPerUnit
                                    : product?.priceBuyPerUnit)
                              )}
                            </label>
                          </CCol>
                        </CRow>
                      );
                    })}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-4">
              <CCol sm="8"></CCol>
              <CCol sm="4">
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("SubTotal")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total
                        ? "RM" + twoDecimal(total - delivery + discount)
                        : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Discount")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + twoDecimal(discount) : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Delivery")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + twoDecimal(delivery) : "-"}
                    </h5>
                  </CCol>
                </CRow>
                {["DEPOSIT", "SALES"].includes(
                  transactionInfo?.data?.configType
                ) && (
                  <CRow>
                    <CCol>
                      <h5 style={{ color: "black" }} className="float-right">
                        {t("Deposit")}:
                      </h5>
                    </CCol>
                    <CCol>
                      <h5 className="float-right">
                        {deposit ? "RM" + twoDecimal(deposit) : "-"}
                      </h5>
                    </CCol>
                  </CRow>
                )}
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Total")}:
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + twoDecimal(total) : "-"}
                    </h5>
                  </CCol>
                </CRow>
                <CRow
                  className="mt-2"
                  style={{
                    background: "#B9B9B9",
                    color: "black",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <CCol>
                    <h5 style={{ color: "black" }} className="float-right">
                      {t("Total Due")}
                    </h5>
                  </CCol>
                  <CCol>
                    <h5 className="float-right">
                      {total ? "RM" + twoDecimal(balance) : "-"}
                    </h5>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="12">
                <CRow>
                  <CCol>
                    <h5 style={{ color: "black" }}>{t("Terms / Notes")}</h5>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol
                    className="align-text-bottom"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {notes || defaultTnC
                      ? (defaultTnC ?? "") + "\n" + (notes ?? "")
                      : ""}
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol className="text-center align-middle">
                <CLabel>{defaultFooter}</CLabel>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </CContainer>
  ) : (
    <CContainer>
      <CRow className="justify-content-center flex-col">
        <div className="d-flex justify-content-center">
          <p>
            Your invoice might be deleted or updated. Please request new invoice
            from your seller
          </p>
        </div>
      </CRow>
    </CContainer>
  );
};

export default InvoicePublic;
