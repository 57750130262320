import moment from "moment-timezone";
import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: "responsive",
  reqStatus: {
    show: false,
    message: "Failed to handle request",
    color: "warning",
  },
  auth: false,
  role: [],
  privilege: [],
  appContext: null,
  projectContext: {
    projectId: null,
    projectConfigId: null,
    transactionId: null,
    bankId: null,
  },
  campaignContext: {
    campaignId: null,
  },
  subscriptionContext: {
    subscriptionId: null,
  },
  bankContext: {
    bankId: null,
  },
  partnerContext: {
    partnerId: null,
  },
  partnerGroupContext: {
    groupId: null,
  },
  inventoryContext: {
    inventoryId: null,
  },
  productContext: {
    productId: null,
  },
  orderContext: {
    orderId: null,
  },
  invoiceContext: {},
  invoiceDateFilterStart: new Date(moment().startOf("month").valueOf()),
  invoiceDateFilterEnd: new Date(moment().endOf("month").valueOf()),
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "clearStore":
      state = undefined;
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);
const store = createStore(persistedReducer);
const persistor = persistStore(store);
export { persistor, store };
