import { CInput } from "@coreui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { onFilterChangeHandler } from "src/utils/tableUtils";
export const DateRangePicker = (props) => {
  const { t } = useTranslation();
  const handleDateConversion = (val, end) => {
    if (end) {
      return moment(new Date(val)).endOf("day").valueOf();
    }
    return moment(new Date(val)).startOf("day").valueOf();
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="mr-2">
        {t("From")}:{" "}
        <CInput
          type="date"
          className="col-input"
          placeholder="from"
          onChange={(e) => {
            if (props.setStartDateFilter) {
              props.setStartDateFilter(handleDateConversion(e.target.value));
            } else {
              onFilterChangeHandler(
                props?.search,
                props?.setSearch,
                props?.statusType,
                handleDateConversion(e.target.value),
                ">="
              );
            }
          }}
        />
      </div>
      <div>
        {t("To")}:{" "}
        <CInput
          type="date"
          className="col-input"
          onChange={(e) => {
            if (props.setEndDateFilter) {
              props.setEndDateFilter(
                handleDateConversion(e.target.value, true)
              );
            } else {
              onFilterChangeHandler(
                props?.search,
                props?.setSearch,
                props?.statusType,
                handleDateConversion(e.target.value, true),
                "<="
              );
            }
          }}
        />
      </div>
    </div>
  );
};
