import Cookies from "js-cookie";
import { useMutation, useQueryCache } from "react-query";
import { useDispatch } from "react-redux";
import { persistor } from "../../stores/store";
import { get } from "../dataService";

export function useAuth() {
  const dispatch = useDispatch();
  return {
    set: (val) => {
      dispatch({
        type: "set",
        auth: {
          active: val,
        },
      });
    },

    permission: (obj) => {
      setCookie("active", true);
      const val = obj.data;
      var privilege = [];
      var role = [];
      for (var k in val) {
        if (typeof val[k] === "object" && val[k] !== null) {
          val[k].forEach((ele) => {
            privilege.push(ele);
          });
          role.push(k);
        }
      }
      dispatch({
        type: "set",
        role: role,
        privilege: privilege,
        auth: true,
      });
    },
  };
}

export const useSignOut = () => {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const [signOutFromServer] = useMutation(
    () =>
      get({
        path: "/account/signOut",
      }),
    {
      onSuccess: () => {
        setCookie("active", false);
        persistor.purge();
        queryCache.invalidateQueries();
        dispatch({
          type: "clearStore",
        });
        window.androidToken(null);
      },
    }
  );

  return {
    signOut: () => signOutFromServer(),
  };
};

// When you use http://localhost:3001 for the frontend, your frontend and backend servers
//  share the same domain (even being on different ports), so you can see the cookie set
// by a request to your backend server (and so linked to backend server domain).
// When you use different domains, you just can't see the cookie as dev tools are attached
// to a page that belongs to another domain. But the cookie is saved and will be sent back with subsequent requests.

// different orign can't be set cookie, browser not receive it
export const getCookie = (key) => {
  return Cookies.get(key);
};

export const setCookie = (key, val) => {
  Cookies.set(key, val, { path: "/", sameSite: "Lax" });
};
