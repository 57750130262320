export const ms = {
  "Add New": "Tambah baru",
  From: "Daripada",
  To: "Sehingga",
  Project: "Cawangan",
  project: "Cawangan",
  "New Project": "Cawangan baru",
  "Edit Project": "Edit Cawangan",
  "Add New Project": "Tambah Cawangan baru",
  "Project Title": "Nama Cawangan",
  "Select Project to use": "Pilih Cawangan",
  "New account": "Akaun baru",
  "Edit account": "Edit akaun",
  "Add New Account": "Tambah akaun baru",
  "Select Account to use": "Pilih akaun",
  "Quotation / PO / SO": "Sebut harga / PO / SO",
  "Add New Invoice": "Tambah invois baru",
  "Account Name": "Nama akaun",
  "Account Type": "Jenis akaun",
  "Parent Account": "Akaun induk",
  "Select parent account (optional)": "Pilih akaun induk (optional)",
  Transactions: "Transaksi",
  "Transaction Date": "Tarikh Invois",
  "Payment Date": "Tarikh Bayaran",
  "New sale / Other Income": "Jualan / pendapatan baru",
  "Other Invoice": "Invois lain",
  Capital: "kapital",
  "sale/Other Income": "Jualan/pendapatan",
  "New expense": "Perbelanjaan baru",
  "Due date": "Tarikh jangkaan",
  "Due Date": "Tarikh Jangkaan",
  "Payment Balance (RM)": "Baki Pembayaran",
  "Total depreciation": "Susut nilai",
  Depreciation: "Susut nilai",
  depreciation: "Susut nilai",
  Cost: "Kos",
  "depreciation (-)": "Susut nilai (-)",
  refund: "Bayaran balik pelanggan",
  "Total improvement": "Nilai tambahan",
  "Total cost required for asset improvement RM:":
    "Jumlah kos diperlukan untuk menambah kecekapan aset",
  "Total liabilities": "liabiliti",
  Ratio: "nisbah",
  "Tax Allowance": "Elaun cukai",
  Method: "Kaedah pembayaran",
  "value (RM)": "Nilai (RM)",
  "Click to view more details": "Klik untuk lebih info",
  VALUE: "Nilai",
  DEPRECIATION: "Susut Nilai",
  "CAPITAL IMPROVEMENT": "Nilai tambahan",
  LIABILITIES: "Liabiliti",
  Liabilities: "Liabiliti",
  "Total Transaction": "Jumlah transaksi",
  partner: "pelanggan/pelabur",
  Partner: "pelanggan/pelabur",
  "Partner List": "Senarai pelanggan/pelabur",
  "Group List": "Kumpulan",
  Report: "Laporan",
  Service: "Servis",
  "Waiter form": "App pelayan",
  Setting: "Tetapan",
  note: "nota",
  Note: "Nota",
  "Invoice No": "No invois",
  "Order No": "No pesanan",
  Order: "Pesanan",
  "search by order no": "No pesanan",
  "Quotation No": "No sebut harga",
  "Created At": "Tarikh disimpan",
  "Start Date": "Tarikh mula",
  "Last Update": "Kemaskini terakhir",
  "End Date": "Tarikh akhir",
  "start nett profit": "Untung permulaan",
  "Disable zakat": "Nyahaktifkan zakat",
  "Disable tax": "Nyahaktifkan cukai",
  "Enable bank sync": "Aktifkan bank penyelarasan",
  "Select Bank as default for future transactions":
    "Pilih bank sebagai default",
  "Start Date can't be ahead of end Date":
    "Tarikh mula tidak dapat melebihi tarikh akhir",
  "It will delete all associated accounts and transactions, Confirm to delete this project?":
    "Memadamkan cawangan akan turut memadam akaun dan transaksi",
  "This make your template (except transactions) available in template collections. Give a description/guidelines about this template":
    "In akan membuatkan templat cawangan anda dipaparkan di koleksi cawangan umum (transaksi tidak termasuk)",
  "It will delete all associated child account and transaction, Confirm delete this account?":
    "Memadam akaun ini akan turut memadam sub-akaun dan transaksi",
  info: "info",
  "Need some more data": "Sila masukkan penerangan/kegunaan cawangan anda",
  save: "simpan",
  saved: "Berjaya disimpan",
  Approved: "Diluluskan",
  success: "Sukses",
  delete: "padam",
  create: "simpan",
  Status: "Status",
  Action: "Tindakan",
  PAID: "Bayaran selesai",
  PARTIAL: "Bayaran separa",
  UNPAID: "Belum dibayar",
  CANCELED: "Dibatalkan",
  PRINTED: "Telah dicetak",
  ACCEPTED: "Diterima",
  DRAFT: "Draf",
  Draft: "Draf",
  PROGRESS: "Proses",
  CLOSED: "Tutup",
  PENDING: "Tangguh",
  Pending: "Tangguh",
  REJECTED: "Dibatalkan",
  PENDING_REVIEW: "MENUNGGU DISEMAK OLEH ADMIN",
  GO_LIVE: "LIVE",
  SALES: "JUALAN",
  SALE: "JUALAN",
  Sale: "Jualan",
  "OTHER INCOME": "PENDAPATAN LAIN",
  PURCHASE: "PEMBELIAN",
  Purchase: "Pembelian",
  "New purchase": "Pembelian baru",
  "Refund & non-deductable expenses":
    "Pembayaran balik & Perbelanjaan tidak boleh ditolak",
  EXPENSES: "PERBELANJAAN",
  Expense: "Perbelanjaan",
  "Total expense": "Jumlah perbelanjaan",
  REFUND: "PEMBAYARAN BALIK",
  CAPITAL: "MODAL",
  "BUSINESS LOAN": "PINJAMAN PERNIAGAAN",
  Asset: "Aset",
  "FIXED ASSET": "ASET TETAP",
  "Fixed asset": "Aset tetap",
  "New fixed asset": "Aset tetap baru",
  "CURRENT ASSET": "ASET SEMASA",
  "Current asset": "Aset semasa",
  "New current asset": "Aset semasa baru",
  "Payment Transaction": "Transaksi bayaran",
  "create & add payment details": "simpan & tambah bayaran",
  "No item, add new line": "Tiada item, tambah item baru",
  "Apply different bank": "Guna bank lain",
  "Sale/Purchase Order": "PO/SO",
  "Purchase order": "Pesanan pembelian",
  "Sale order": "Pesanan jualan",
  "New purchase/sale order": "PO/SO baru",
  "Order type": "Jenis pesanan",
  "NON-DEDUCTIBLE EXPENSES": "PERBELANJAAN TIDAK BOLEH DITOLAK",
  "DIVIDED PAYMENT": "PEMBAYARAN DIVIDEN",
  accounts: "akaun",
  Accounts: "Akaun",
  Account: "Akaun",
  INVOICE: "INVOIS",
  invoice: "Invois",
  Invoice: "Invois",
  copy: "Salin",
  "New invoice": "Invois baru",
  payments: "Pembayaran",
  CASH: "Tunai",
  BANK_TRANSFER: "Pindahan bank",
  CHEQUE: "Cek",
  edit: "edit",
  "Showing ": "Menunjukkan ",
  " out of ": " daripada ",
  Home: "Laman utama",
  error: "ralat",
  "Something went wrong!": "Berlaku keralatan",
  Blank: "Kosong",
  "must be filled": "mesti diisi",
  "empty inputs": "Input kosong",
  "No items": "Tiada data",
  Dashboard: "Laman utama",
  Quotation: "Sebut harga",
  "New Quotation": "Sebut harga baru",
  "Add New Quotation": "Tambah sebut harga",
  Inventory: "inventori",
  inventory: "inventori",
  "Select Inventory to use": "Pilih inventory",
  ACTIVE: "AKTIF",
  CLOSE: "TUTUP",
  "New Bank": "Bank baru",
  Name: "Nama",
  Type: "Jenis",
  "Account number": "Akaun number",
  "Opening balance": "Baki Awal",
  Balance: "Baki",
  "Add New Bank": "Tambah bank baru",
  "Add New Order": "Tambah pesanan baru",
  "New Order": "Pesanan baru",
  "(Waiter form)": "(Borang pelayan)",
  "Confirm delete this bank?": "Mengesahkan untuk padam bank ini?",
  deleted: "Dipadam",
  In: "Duit masuk",
  Out: "Duit keluar",
  NEW: "BARU",
  New: "Baru",
  Update: "Kemas kini",
  UPDATED: "DIKEMAS KINI",
  PAYMENT_CONFIRMED: "BAYARAN DIBUAT",
  PAYMENT_UPDATED: "BAYARAN DIKEMAS KINI",
  ORDER_DELIVERED: "PESANAN DIHANTAR",
  "Download transaction": "Muat turun transaksi",
  "All paid Invoice": "Semua invoice yang telah dibayar",
  Qty: "kuantiti",
  "Price/unit (RM)": "harga/unit (RM)",
  subTotal: "Jumlah kecil",
  "add payment details": "tambah pembayaran",
  "save & print": "simpan & print",
  "Bill Type": "Jenis bill",
  "Paid Amount": "Jumlah Dibayar",
  "Price/unit": "Harga/unit",
  "Sub Price": "Harga kecil",
  Amount: "Jumlah Bayaran",
  "Terms / Notes": "Terma / Nota",
  Items: "Barangan",
  "Total Due Now": "Jumlah Tertunggak",
  "Payment Status": "Status Pembayaran",
  "Payment Balance": "Baki bayaran",
  Change: "Baki pulangan",
  "Update product stock": "Kemas kini kuantiti stok",
  "Create & change partner": "Cipta & tukar pelanggan/pelabur",
  "partner email": "Emel pelanggan/pelabur",
  "Partner name": "Nama pelanggan/pelabur",
  "Partner handphone": "Tel pelanggan/pelabur",
  "Partner address": "Alamat pelanggan/pelabur",
  "Partner postcode": "Postcode pelanggan/pelabur",
  "Partner group": "Kumpulan pelanggan/pelabur",
  "Account type": "Jenis akaun",
  "ALL BELOW": "SEMUA DIBAWAH",
  "generate invoice": "Hasilkan invois",
  "Asset value": "Nilai aset",
  "Add New Asset": "Tambah aset baru",
  "Edit Asset": "Edit aset",
  "Total asset value RM:": "Jumlah aset RM:",
  Total: "Jumlah",
  "Total curent depreciation RM:": "Jumlah susut nilai aset semasa RM:",
  "Search partner by name, email or phone number":
    "Cari dengan nama, emel atau hp no",
  "Reference No": "No rujukan",
  "View catalog": "Lihat katalog",
  "Scan barcode": "Imbas kod bar",
  "Total Quotation RM:": "Jumlah sebut harga",
  handphone: "Tel",
  Phone: "Telefon",
  Inventories: "Inventori",
  Inventory: "Inventori",
  "New Inventory": "Inventori baru",
  products: "Produk",
  Product: "Produk",
  "New Model": "Model baru",
  "Create New Model": "Cipta model baru",
  "Add New Inventory": "Tambah inventori baru",
  "Edit Inventory": "Edit inventori",
  Close: "Tutup",
  "transaction logs": "Log transaksi",
  "barcode list": "Senarai bar kod",
  "Ready stock": "Stock sedia ada",
  Brand: "Jenama",
  category: "kategori",
  "New Product": "Produk baru",
  Logs: "Log",
  Sell: "Jualan",
  Adjustment: "Pelarasan",
  Restock: "Restock",
  "Create New Product": "Tambah produk baru",
  Moq: "Minimum kuantiti untuk pesanan",
  "Price buy/unit": "Kos/unit",
  "Price Sell/unit": "Harga jualan/unit",
  "Insert a unique SKU prefix else will auto generated":
    "Masukkan SKU prefix atau auto dijana oleh sistem",
  "Value per unit measurement": "Nilai/unit",
  "Unit Measurement": "Unit ukuran",
  "Search by printer name": "Cari mengunakan nama printer",
  "Give a comment on quantity adjustment":
    "Masukkan nota untuk penyelarasan kuantiti",
  "all transaction associated with this product will remain as it is. Confirm delete this Product?":
    "Semua transaksi produk akan kekal.",
  "Update Product": "Edit produk",
  "New Partner": "pelanggan/pelabur baru",
  "Add New Partner": "Tambah pelanggan/pelabur baru",
  Company: "Syarikat",
  "Contact Person": "Nama wakil dihubungi",
  "Edit Partner": "Edit pelanggan/pelabur",
  Email: "Emel",
  "Last Login": "Log masuk terakhir",
  Address: "Alamat",
  Postcode: "Poskod",
  groups: "Kumpulan",
  Group: "Kumpulan pelanggan/pelabur",
  "New Group": "Kumpulan baru",
  "Add New Group": "Tambah baru",
  "Edit Group": "Edit kunpulan",
  "Select group": "Pilih kumpulan",
  "Balance due": "Baki bayaran",
  "New Asset Payment": "Bayaran/susut nilai aset",
  "New Payment": "Bayaran baru",
  "Payment details of": "Butiran bayaran",
  "Edit Asset Payment": "Edit bayaran aset",
  "Add New Asset Payment": "Tambah bayaran aset",
  "Please select payment type": "Pilih jenis pembayaran",
  "Select Bank to use": "Pilih bank bayaran dibuat",
  "Edit Payment": "Edit bayaran",
  "Add New Payment": "Tambah bayaran baru",
  "Select report type to generate": "Pilih jenis laporan",
  generate: "Hasilkan",
  "Income Statement": "Penyata untung rugi",
  "Income statement (P&L)": "Penyata untung rugi",
  Revenues: "Pendapatan",
  revenues: "Pendapatan",
  Profit: "Untung",
  profit: "Untung",
  Lost: "kerugian",
  "Total revenue": "Jumlah pendapatan",
  cogs: "Kos produk dijual",
  "gross profit": "Keuntungan kasar",
  expenses: "Perbelanjaan",
  "operating income": "Keuntungan operasi",
  earning: "Pendapatan bersih",
  Download: "Muat turun",
  "Cash Flow": "Penyata aliran tunai",
  "Monthly cash flow": "Aliran tunai bulanan",
  Monthly: "Bulanan",
  Customer: "Pelanggan",
  "Revenue top 9 customer": "Untung 9 pelanggan tertinggi",
  "Revenue top 3 customer": "Untung 3 pelanggan tertinggi",
  "Revenue top 9 product": "Untung 9 produk tertinggi",
  "Revenue top 2 product": "Untung 2 produk tertinggi",
  "account receivable": "Akaun penghutang",
  "Account receivable": "Akaun penghutang",
  "Inventory increase": "Stok sedia ada",
  "inventory (current stock - weighted)": "Inventori (Purata wajaran)",
  "Account payable": "Akaun pemiutang (jangka panjang)",
  "long term payable": "Akaun pemiutang (jangka panjang)",
  "Accrued expenses": "Perbelanjaan terkumpul (Akruan)",
  "accrued expenses": "Perbelanjaan terkumpul (Akruan)",
  "Capital expenditure": "Perbelanjaan modal",
  "Note payable": "Akaun pemiutang (semasa)",
  "note payable": "Akaun pemiutang (semasa)",
  cash: "Tunai",
  "retain earning": "Pendapatan/Kerugian tertahan",
  "Trial Balance": "Penyata imbangan duga",
  Credit: "Kredit",
  Debit: "Debit",
  "Balance Sheet": "Penyata imbangan",
  "Monthly balance sheet": "Penyata imbangan bulanan",
  Quarterly: "setiap suku tahun",
  Assets: "Aset",
  Equity: "Ekuiti",
  "Equity + Liabilities": "Ekuiti + Liabiliti",
  "property, plant & equipment": "Harta benda, tumbuhan & peralatan",
  "Sale Entry": "Entri jualan",
  "Pay & Print": "Bayar & print",
  "Remove product from partner for invoice": "Abaikan produk dari kiosk",
  "Focus barcode scanner's entry": "Fokus input pengimbas bar kod",
  "Print order by account": "Print pesanan mengikut jenis akaun",
  "Update product quantity upon order delivered":
    "Kemas kini produk kuantiti pada status pesanan dihantar",
  "Default bank": "Bank default",
  "New Printer": "Printer baru",
  Detail: "Perincian",
  "Add New Printer": "Tambah printer baru",
  Profile: "Profil",
  "Company Profile": "Profil syarikat",
  "Edit Company Profile": "Edit profil syarikat",
  Username: "Nama pengguna",
  User: "Pengguna",
  "Tax no": "No cukai",
  "Invite staff": "Jemput rakan kerja",
  Subscription: "Langganan",
  "Notification setting": "Tetapan notifikasi",
  Notification: "Notifikasi",
  Logout: "Keluar",
  Support: "Saluran sokongan",
  "Company hp": "Hp syarikat",
  "Company name": "Nama syarikat",
  "Company Address": "Alamat syarikat",
  Invite: "Jemput",
  "Notify on new invoice": "invois baru",
  "Notify on invoice update": "invois kemas kini",
  "Notify on new quotation": "sebut harga baru",
  "Notify on quotation update": "sebut harga kemas kini",
  "Notify on new order": "pesanan baru",
  "Notify on order update": "pesanan kemas kini",
  "Manage Subscription": "Urus langganan",
  "Subscribe Now": "Beli sekarang",
  "Dividen payment": "Pembayaran dividen",
  "Capital deposit": "Deposit modal",
  Device: "Peranti",
  "Do you want to create from template?": "Bina dari templat akaun?",
  Yes: "Ya",
  No: "Tidak",
  Capital: "Modal",
  "Last 3 months": "Sejak 3 bulan lepas",
  "Last 6 months": "Sejak 6 bulan lepas",
  "Last 12 months": "Sejak 12 bulan lepas",
  "Positive cashflow": "Positif aliran tunai",
  "Negative cashflow": "Negatif aliran tunai",
  "Copy this template": "Salinan templat ini",
  "product conciliation": "Penilaian produk",
  "Reconciliation date": "Tarikh penilaian",
  "New Conciliation": "Penilaian baru",
  documentation: "dokumentasi",
  language: "Bahasa",
  "Confirm to delete this transaction?": "Padam transaksi ini?",
  "Confirm to delete this Asset": "Padam aset ini?",
  "Monthly income statement report": "laporan penyata pendapatan bulanan",
  "Effecting date": "Tarikh penyata terkesan",
  "Missing order config, please setup order configuration":
    "Sila tetapkan tetapan order dahulu",
  "Import transactions": "Import transaksi",
  "download csv template": "Muat turun templat csv",
  "upload transactions (csv)": "Muat naik transaksi csv",
  "Copy below akaun detail for later reference":
    "Salin detail akaun di bawah untuk digunakan dalam templat",
  "Count cogs direct from purchase invoices":
    "Kira cogs terus dari invoise pembelian",
  "Issue Date": "Tarikh Dikeluarkan",
  transfer: "Pindah wang",
  "Transfer Fund": "Pemindahan Wang",
  "more details": "extra",
  "link copied to clipboard": "Pautan disalin ke papan keratan",
  "Copy link": "Salin pautan",
};
