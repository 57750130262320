import React, { Component } from "react";

const Page500 = React.lazy(() => import("../page500/Page500"));

// the error boundary is overlay in development mode by showing the error
// this will show properly in production mode

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO log the error to server
    console.debug(error);
  }

  render() {
    if (this.state.hasError) {
      return <Page500 />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
